@import './style/constants';

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'), url(./fonts/Nunito-Regular.ttf) format('truetype');
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Nunito', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h1,
h2 {
    color: $color-primary;
}

h3,
h4,
h5,
h6 {
    color: $color-secundary;
}

@import '~bootstrap/scss/bootstrap.scss';